/* Provide sufficient contrast against white background */
html {
    font-size: 14px;
}

#root {
    height: 100%;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.mr-3 {
    margin-right: 1rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.btn-primary, .p-button {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.p-datatable .p-datatable-thead > tr > th, .p-datatable .p-datatable-thead > tr > th.p-sortable-column {
    background-color: #EAEAEA;
}

.p-datatable .p-datatable-header {
    margin-bottom: 0 !important;
    background-color: #EAEAEA;
    padding-top: 10px;
    padding-bottom: 10px;
}

.p-tabview .p-tabview-panels {
    padding: 0px 0px 6px 0px;
}

    .p-tabview .p-tabview-panels .form-header {
        background-color: #EAEAEA;
    }

.p-datatable .p-datatable-tbody .p-rowgroup-header {
    background-color: #BFDFFF;
}

.form-container {
    margin: 0 12px;
}

.page-title {
    padding-left: 18px;
}

.btns {
    margin-right: 5px;
}

.flex {
    display: flex !important;
}

.form-header.flex {
    flex-wrap: wrap;
}

.control-max-width-100 {
    max-width: 100px;
}

.control-max-width-200 {
    max-width: 200px;
}

.control-max-width-300 {
    max-width: 300px;
}

.form-header {
    background: #efefef;
    color: #212529;
    border: solid #dee2e6;
    border-width: 1px 0 0 0;
    padding: 10px 1rem;
    font-weight: 600;
}

.display-none {
    display: none;
}

.dokument-item {
    text-align: start;
}

.hidden-visibility {
    visibility: hidden;
}

.p-fileupload-file-thumbnail {
    display: none;
}

.p-fileupload-row > div:last-child {
    text-align: start;
}

.btn-upload .p-fileupload-choose {
    background: initial;
    border: initial;
    padding: initial;
}

.btn-upload .p-fileupload-buttonbar {
    background: initial;
    border: initial;
    padding: initial;
}

.btn-upload .p-fileupload-content {
    display: none;
}

.p-dropdown.p-dropdown-fullwidth {
    width: 100%;
    min-width: 200px;
}

.p-dropdown {
    width: 200px;
}

    .p-dropdown.p-disabled {
        background-color: #e9ecef !important;
        color: #000000 !important;
    }

.advanced-filter-item {
    width: 330px;
}

.advanced-filter-container {
    width: 390px !important;
}

@media (max-width: 480px) {
    .advanced-filter-item {
        width: 260px;
    }

    .advanced-filter-container {
        width: 320px !important;
    }
}

.advanced-filter-container .p-sidebar-header {
    justify-content: space-between !important;
}

.p-input-icon-left > i {
    top: 20px;
}

.p-message-detail {
    white-space: break-spaces;
}

.dashboard-dropdown-success .p-dropdown-trigger {
    color: #ffffff;
    background: #28a745;
    border: 1px solid #28a745;
    border-radius: 0 4px 4px 0;
}

.dashboard-dropdown-primary .p-dropdown-trigger {
    color: #ffffff;
    background: #0d6efd;
    border: 1px solid #0d6efd;
    border-radius: 0 4px 4px 0;
}

.dashboard-izabrani-predmeti .p-datatable-thead, .dashboard-aktivna-rocista .p-datatable-thead {
    display: none;
}

.phone-number-call-icon {
    background-color: #37C837;
    display: inline-block;
    padding: 4px;
    color: white;
}

@media (min-width: 768px) {
    .phone-number-call-icon {
        display: none;
    }
}

.search-table {
    max-width: 200px;
}

@media (max-width: 480px) {
    .search-table {
        max-width: 150px;
    }
}

@media (max-width: 480px) {
    .hide-on-xs {
        display: none;
    }
}

@media (max-width: 480px) {
    .mb-2-xs {
        margin-bottom: 0.5rem !important;
    }
}

.form-control-prime {
    width: 100%;
}

.help-tooltip-image {
    cursor: pointer;
    margin-left: 6px;
    margin-top: -8px;
}

.p-overlaypanel {
    max-width: 400px;
}

    .p-overlaypanel p {
        margin: 0;
    }

.home-logo {
    margin: 4px 6px 4px 38px;
    height: 42px;
}

@media (max-width: 992px) {
    .hide-on-mobile {
        display: none !important;
    }
}

@media (min-width: 992px) {
    .show-on-mobile {
        display: none;
    }
}

@media (max-width: 350px) {
    .mb-3-for-less-than-350 {
        margin-bottom: 1rem !important;
    }
}

@media (max-width: 400px) {
    .mb-3-for-less-than-400 {
        margin-bottom: 1rem !important;
    }
}

.dashboard-obaveze-danas-sutra ul {
    padding-left: 1rem;
}

@media (max-width: 768px) {
    .hide-on-768 {
        display: none;
    }
}

@media (min-width: 769px) {
    .show-on-768 {
        display: none;
    }
}

@media (min-width: 601px) {
    .show-on-600 {
        display: none;
    }
}

@media (max-width: 600px) {
    .hide-on-600 {
        display: none;
    }
}

.calendar-month-day-number {
    font-size: 1.4rem;
}

.calendar-month-day-number-active {
    background-color: #BFDFFF;
}


@media (max-width: 480px) {
    .calendar-month-day-number {
        font-size: 1.2rem;
    }
}

@media (min-width: 720px) {
    .mb-1-for-more-than-720 {
        margin-bottom: 0.25rem !important;
    }
}

.calendar-month td {
    padding: 0.25rem !important;
}

.calendar-month-item-template .p-badge, .table-satnica .p-badge {
    padding: 0 0.2rem;
}

.calendar-month-item-template {
    min-height: 60px;
}

.font-size-9 {
    font-size: 0.9rem;
}

.table-satnica.p-datatable .p-datatable-thead tr th {
    border-top: none;
}

.table-satnica.p-datatable .p-datatable-tbody tr td:not(:first-of-type) {
    padding: 0 2px 0 4px;
    font-size: 0.9rem !important;
}

.table-satnica-single-item {
    border-bottom: 1px solid #E5E5E5;
    padding: 6px 0 6px 0;
    cursor: pointer;
}

    .table-satnica-single-item:last-of-type {
        border-bottom: none;
    }

.table-satnica .hour-column {
    box-shadow: inset -1px 0 #dee2e6;
}

.cursor-pointer {
    cursor: pointer;
    user-select: none;
}

fieldset {
    min-width: inherit;
    padding: inherit;
    margin: inherit;
}

legend {
    float: inherit;
    width: inherit;
    margin-bottom: inherit;
    line-height: inherit;
    font-size: inherit;
}

.table-grouped-column-header .p-column-header-content {
    justify-content: center;
    border-right: 1px solid black;
}

.btn-existing-document {
    padding-left: 2px;
}
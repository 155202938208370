$text-color: #7d84ab;
$secondary-text-color: #dee2ec;

$bg-color: #0c1e35;
$secondary-bg-color: #0b1a2c;

$border-color: rgba(#535d7d, 0.3);

$sidebar-header-height: 100px;
$sidebar-footer-height: 230px;

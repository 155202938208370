@font-face {
    font-family: 'NotoSansRegular';
    font-style: normal;
    font-weight: normal;
    src: local('./fonts/NotoSansRegular'), url('./fonts/NotoSansRegular.woff') format('woff');
}

@font-face {
    font-family: 'Segoe UI Regular';
    font-style: normal;
    font-weight: normal;
    src: local('./fonts/Segoe UI Regular'), url('./fonts/Segoe UI.woff') format('woff');
}


@font-face {
    font-family: 'Segoe UI Italic';
    font-style: normal;
    font-weight: normal;
    src: local('./fonts/Segoe UI Italic'), url('./fonts/Segoe UI Italic.woff') format('woff');
}


@font-face {
    font-family: 'Segoe UI Bold';
    font-style: normal;
    font-weight: normal;
    src: local('./fonts/Segoe UI Bold'), url('./fonts/Segoe UI Bold.woff') format('woff');
}


@font-face {
    font-family: 'Segoe UI Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('./fonts/Segoe UI Bold Italic'), url('./fonts/Segoe UI Bold Italic.woff') format('woff');
}

.layout {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
}

    .layout.has-sidebar {
        flex-direction: row;
    }

    .layout .header {
        transition: width, 0.3s;
        height: 64px;
        min-height: 64px;
        position: relative;
    }

    .layout .sidebar {
        width: 250px;
        min-width: 250px;
        transition: width, left, right, 0.3s;
    }

        .layout .sidebar.collapsed {
            width: 80px;
            min-width: 80px;
        }

@media (max-width: 480px) {
    .layout .sidebar.break-point-xs {
        position: fixed;
        right: -250px;
        left: auto;
        height: 100%;
        top: 0;
        z-index: 100;
    }

        .layout .sidebar.break-point-xs.collapsed {
            right: -80px;
            left: auto;
        }

        .layout .sidebar.break-point-xs.toggled {
            right: 0;
            left: auto;
        }

            .layout .sidebar.break-point-xs.toggled ~ .overlay {
                display: block;
            }

        .layout .sidebar.break-point-xs ~ .layout .header {
            width: 100% !important;
            transition: none;
        }
}

@media (max-width: 576px) {
    .layout .sidebar.break-point-sm {
        position: fixed;
        right: -250px;
        left: auto;
        height: 100%;
        top: 0;
        z-index: 100;
    }

        .layout .sidebar.break-point-sm.collapsed {
            right: -80px;
            left: auto;
        }

        .layout .sidebar.break-point-sm.toggled {
            right: 0;
            left: auto;
        }

            .layout .sidebar.break-point-sm.toggled ~ .overlay {
                display: block;
            }

        .layout .sidebar.break-point-sm ~ .layout .header {
            width: 100% !important;
            transition: none;
        }
}

@media (max-width: 768px) {
    .layout .sidebar.break-point-md {
        position: fixed;
        right: -250px;
        left: auto;
        height: 100%;
        top: 0;
        z-index: 100;
    }

        .layout .sidebar.break-point-md.collapsed {
            right: -80px;
            left: auto;
        }

        .layout .sidebar.break-point-md.toggled {
            right: 0;
            left: auto;
        }

            .layout .sidebar.break-point-md.toggled ~ .overlay {
                display: block;
            }

        .layout .sidebar.break-point-md ~ .layout .header {
            width: 100% !important;
            transition: none;
        }
}

@media (max-width: 992px) {
    .layout .sidebar.break-point-lg {
        position: fixed;
        right: -250px;
        left: auto;
        height: 100%;
        top: 0;
        z-index: 100;
    }

        .layout .sidebar.break-point-lg.collapsed {
            right: -80px;
            left: auto;
        }

        .layout .sidebar.break-point-lg.toggled {
            right: 0;
            left: auto;
        }

            .layout .sidebar.break-point-lg.toggled ~ .overlay {
                display: block;
            }

        .layout .sidebar.break-point-lg ~ .layout .header {
            width: 100% !important;
            transition: none;
        }
}

@media (max-width: 1200px) {
    .layout .sidebar.break-point-xl {
        position: fixed;
        right: -250px;
        left: auto;
        height: 100%;
        top: 0;
        z-index: 100;
    }

        .layout .sidebar.break-point-xl.collapsed {
            right: -80px;
            left: auto;
        }

        .layout .sidebar.break-point-xl.toggled {
            right: 0;
            left: auto;
        }

            .layout .sidebar.break-point-xl.toggled ~ .overlay {
                display: block;
            }

        .layout .sidebar.break-point-xl ~ .layout .header {
            width: 100% !important;
            transition: none;
        }
}

@media (max-width: 1600px) {
    .layout .sidebar.break-point-xxl {
        position: fixed;
        right: -250px;
        left: auto;
        height: 100%;
        top: 0;
        z-index: 100;
    }

        .layout .sidebar.break-point-xxl.collapsed {
            right: -80px;
            left: auto;
        }

        .layout .sidebar.break-point-xxl.toggled {
            right: 0;
            left: auto;
        }

            .layout .sidebar.break-point-xxl.toggled ~ .overlay {
                display: block;
            }

        .layout .sidebar.break-point-xxl ~ .layout .header {
            width: 100% !important;
            transition: none;
        }
}

.layout .footer {
    height: 64px;
    min-height: 64px;
}

.layout .content {
    flex-grow: 1;
}

.layout .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
    display: none;
}

.layout .sidebar-toggler {
    display: none;
}

@media (max-width: 480px) {
    .layout .sidebar-toggler.break-point-xs {
        display: initial;
    }
}

@media (max-width: 576px) {
    .layout .sidebar-toggler.break-point-sm {
        display: initial;
    }
}

@media (max-width: 768px) {
    .layout .sidebar-toggler.break-point-md {
        display: initial;
    }
}

@media (max-width: 992px) {
    .layout .sidebar-toggler.break-point-lg {
        display: initial;
    }
}

@media (max-width: 1200px) {
    .layout .sidebar-toggler.break-point-xl {
        display: initial;
    }
}

@media (max-width: 1600px) {
    .layout .sidebar-toggler.break-point-xxl {
        display: initial;
    }
}

.layout.fixed-sidebar {
    height: 100%;
}

    .layout.fixed-sidebar .sidebar {
        height: 100%;
        overflow: auto;
    }

        .layout.fixed-sidebar .sidebar ~ .layout {
            height: 100%;
            overflow: auto;
        }

.layout.fixed-header .header {
    position: fixed;
    width: 100%;
    z-index: 2;
}

    .layout.fixed-header .header ~ .layout,
    .layout.fixed-header .header ~ .content {
        margin-top: 64px;
    }

.layout.fixed-header.fixed-sidebar .header {
    width: calc(100% - 250px);
}

.layout.fixed-header.fixed-sidebar .sidebar.collapsed ~ .layout .header {
    width: calc(100% - 80px);
}

.layout.rtl {
    direction: rtl;
}

@media (max-width: 480px) {
    .layout.rtl .sidebar.break-point-xs {
        left: auto;
        right: -250px;
    }

        .layout.rtl .sidebar.break-point-xs.collapsed {
            left: auto;
            right: -80px;
        }

        .layout.rtl .sidebar.break-point-xs.toggled {
            left: auto;
            right: 0;
        }
}

@media (max-width: 576px) {
    .layout.rtl .sidebar.break-point-sm {
        left: auto;
        right: -250px;
    }

        .layout.rtl .sidebar.break-point-sm.collapsed {
            left: auto;
            right: -80px;
        }

        .layout.rtl .sidebar.break-point-sm.toggled {
            left: auto;
            right: 0;
        }
}

@media (max-width: 768px) {
    .layout.rtl .sidebar.break-point-md {
        left: auto;
        right: -250px;
    }

        .layout.rtl .sidebar.break-point-md.collapsed {
            left: auto;
            right: -80px;
        }

        .layout.rtl .sidebar.break-point-md.toggled {
            left: auto;
            right: 0;
        }
}

@media (max-width: 992px) {
    .layout.rtl .sidebar.break-point-lg {
        left: auto;
        right: -250px;
    }

        .layout.rtl .sidebar.break-point-lg.collapsed {
            left: auto;
            right: -80px;
        }

        .layout.rtl .sidebar.break-point-lg.toggled {
            left: auto;
            right: 0;
        }
}

@media (max-width: 1200px) {
    .layout.rtl .sidebar.break-point-xl {
        left: auto;
        right: -250px;
    }

        .layout.rtl .sidebar.break-point-xl.collapsed {
            left: auto;
            right: -80px;
        }

        .layout.rtl .sidebar.break-point-xl.toggled {
            left: auto;
            right: 0;
        }
}

@media (max-width: 1600px) {
    .layout.rtl .sidebar.break-point-xxl {
        left: auto;
        right: -250px;
    }

        .layout.rtl .sidebar.break-point-xxl.collapsed {
            left: auto;
            right: -80px;
        }

        .layout.rtl .sidebar.break-point-xxl.toggled {
            left: auto;
            right: 0;
        }
}

.layout {
    z-index: 1;
}

    .layout .header {
        display: flex;
        align-items: center;
        padding: 20px;
    }

    .layout .content {
        padding: 6px 6px 12px 0px;
        display: flex;
        flex-direction: column;
    }

    .layout .footer {
        text-align: center;
        margin-top: auto;
        margin-bottom: 20px;
        padding: 20px;
    }

.sidebar {
    /*color: #7d84ab;*/
    color: #171717;
    position: relative;
    height: 100%;
    max-height: 100%;
    overflow: hidden !important;
}

    .sidebar .image-wrapper {
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: none;
    }

        .sidebar .image-wrapper > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

    .sidebar.has-bg-image .sidebar-layout {
        background-color: rgba(12, 30, 53, 0.85);
    }

    .sidebar.has-bg-image .image-wrapper {
        display: block;
    }

    .sidebar .sidebar-layout {
        height: 100%;
        max-height: 100%;
        min-height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        position: relative;
        /*background-color: #0c1e35;*/
        background-color: #EAEAEA;
        z-index: 2;
    }

        .sidebar .sidebar-layout::-webkit-scrollbar-thumb {
            border-radius: 4px;
        }

        .sidebar .sidebar-layout:hover::-webkit-scrollbar-thumb {
            /*background-color: #1a4173;*/
            background-color: #BBBBBB;
        }

        .sidebar .sidebar-layout::-webkit-scrollbar {
            width: 6px;
            /*background-color: #0c1e35;*/
            background-color: #EAEAEA;
        }

        .sidebar .sidebar-layout .sidebar-header {
            height: 60px;
            min-height: 60px;
            display: flex;
            /*align-items: center;*/
            padding: 10px 20px 0 20px;
        }

            .sidebar .sidebar-layout .sidebar-header > span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

        .sidebar .sidebar-layout .sidebar-content {
            flex-grow: 1;
            padding: 0 0 10px 0;
        }

        .sidebar .sidebar-layout .sidebar-footer {
            height: 230px;
            min-height: 230px;
            display: flex;
            align-items: center;
            padding: 0 20px;
        }

            .sidebar .sidebar-layout .sidebar-footer > span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

@keyframes swing {
    0%, 30%, 50%, 70%, 100% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(10deg);
    }

    40% {
        transform: rotate(-10deg);
    }

    60% {
        transform: rotate(5deg);
    }

    80% {
        transform: rotate(-5deg);
    }
}

.layout .sidebar .menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.layout .sidebar .menu .menu-header {
    font-weight: 600;
    padding: 10px 25px;
    font-size: 14px;
    letter-spacing: 2px;
    transition: opacity 0.3s;
    opacity: 0.5;
}

.menu-item {
    font-size: 16px;
}

.layout .sidebar .menu .menu-item.active {
    background-color: #DCDCDC;
}

.layout .sidebar .menu .menu-item:hover {
    background-color: #CFCFCF;
}

.layout .sidebar .menu .menu-item a {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    /*color: #7d84ab;*/
    color: #171717;
}

    .layout .sidebar .menu .menu-item a .menu-icon {
        font-size: 1.2rem;
        width: 35px;
        min-width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        display: inline-block;
        margin-right: 10px;
        border-radius: 2px;
        transition: color 0.3s;
        margin-top: -4px;
    }

        .layout .sidebar .menu .menu-item a .menu-icon i {
            display: inline-block;
        }

        .layout .sidebar .menu .menu-item a .menu-icon img {
            display: inline-block;
        }

    .layout .sidebar .menu .menu-item a .menu-title {
        font-size: 0.9em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-grow: 1;
        transition: color 0.3s;
    }

    .layout .sidebar .menu .menu-item a .menu-prefix,
    .layout .sidebar .menu .menu-item a .menu-suffix {
        display: inline-block;
        padding: 5px;
        opacity: 1;
        transition: opacity 0.3s;
    }

    .layout .sidebar .menu .menu-item a:hover .menu-title {
        /*color: #dee2ec;*/
    }

    .layout .sidebar .menu .menu-item a:hover .menu-icon {
        /*color: #dee2ec;*/
    }

        .layout .sidebar .menu .menu-item a:hover .menu-icon i {
            animation: swing ease-in-out 0.5s 1 alternate;
        }

        .layout .sidebar .menu .menu-item a:hover .menu-icon img {
            animation: swing ease-in-out 0.5s 1 alternate;
        }

    .layout .sidebar .menu .menu-item a:hover::after {
        /*border-color: #dee2ec !important;*/
    }

.layout .sidebar .menu .menu-item.sub-menu {
    position: relative;
    cursor: pointer;
    user-select: none;
}

    .layout .sidebar .menu .menu-item.sub-menu > a::after {
        content: "";
        transition: transform 0.3s;
        border-right: 2px solid currentcolor;
        border-bottom: 2px solid currentcolor;
        width: 5px;
        height: 5px;
        transform: rotate(-45deg);
    }

    .layout .sidebar .menu .menu-item.sub-menu > .sub-menu-list {
        padding-left: 20px;
        display: none;
        overflow: hidden;
        z-index: 999;
    }

    .layout .sidebar .menu .menu-item.sub-menu.open > a {
        /*color: #dee2ec;*/
    }

        .layout .sidebar .menu .menu-item.sub-menu.open > a::after {
            transform: rotate(45deg);
        }

.layout .sidebar .menu .menu-item.active > a .menu-title {
    /*color: #dee2ec;*/
}

.layout .sidebar .menu .menu-item.active > a::after {
    /*border-color: #dee2ec;*/
}

.layout .sidebar .menu .menu-item.active > a .menu-icon {
    /*color: #dee2ec;*/
}

.layout .sidebar .menu > ul > .sub-menu > .sub-menu-list {
    background-color: #EAEAEA;
}

.layout .sidebar .menu.icon-shape-circle .menu-item a .menu-icon, .layout .sidebar .menu.icon-shape-rounded .menu-item a .menu-icon, .layout .sidebar .menu.icon-shape-square .menu-item a .menu-icon {
    background-color: #0b1a2c;
}

.layout .sidebar .menu.icon-shape-circle .menu-item a .menu-icon {
    border-radius: 50%;
}

.layout .sidebar .menu.icon-shape-rounded .menu-item a .menu-icon {
    border-radius: 4px;
}

.layout .sidebar .menu.icon-shape-square .menu-item a .menu-icon {
    border-radius: 0;
}

.layout .sidebar:not(.collapsed) .menu > ul > .menu-item.sub-menu > .sub-menu-list {
    visibility: visible !important;
    position: static !important;
    transform: translate(0, 0) !important;
}

.layout .sidebar.collapsed .menu > ul > .menu-header {
    opacity: 0;
}

.layout .sidebar.collapsed .menu > ul > .menu-item > a .menu-prefix,
.layout .sidebar.collapsed .menu > ul > .menu-item > a .menu-suffix {
    opacity: 0;
}

.layout .sidebar.collapsed .menu > ul > .menu-item.sub-menu > a::after {
    content: "";
    width: 5px;
    height: 5px;
    background-color: currentcolor;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 50%;
    border: none;
    transform: translateY(-50%);
}

.layout .sidebar.collapsed .menu > ul > .menu-item.sub-menu > a:hover::after {
    /*background-color: #dee2ec;*/
}

.layout .sidebar.collapsed .menu > ul > .menu-item.sub-menu > .sub-menu-list {
    transition: none !important;
    width: 200px;
    margin-left: 3px !important;
    border-radius: 4px;
    display: block !important;
}

.layout .sidebar.collapsed .menu > ul > .menu-item.active > a::after {
    background-color: #CFCFCF;
}

.layout .sidebar.has-bg-image .menu.icon-shape-circle .menu-item a .menu-icon, .layout .sidebar.has-bg-image .menu.icon-shape-rounded .menu-item a .menu-icon, .layout .sidebar.has-bg-image .menu.icon-shape-square .menu-item a .menu-icon {
    background-color: rgba(11, 26, 44, 0.6);
}

.layout .sidebar.has-bg-image:not(.collapsed) .menu > ul > .sub-menu > .sub-menu-list {
    background-color: rgba(11, 26, 44, 0.6);
}

.layout.rtl .sidebar .menu .menu-item a .menu-icon {
    margin-left: 10px;
    margin-right: 0;
}

.layout.rtl .sidebar .menu .menu-item.sub-menu > a::after {
    transform: rotate(135deg);
}

.layout.rtl .sidebar .menu .menu-item.sub-menu > .sub-menu-list {
    padding-left: 0;
    padding-right: 20px;
}

.layout.rtl .sidebar .menu .menu-item.sub-menu.open > a::after {
    transform: rotate(45deg);
}

.layout.rtl .sidebar.collapsed .menu > ul > .menu-item.sub-menu a::after {
    right: auto;
    left: 10px;
}

.layout.rtl .sidebar.collapsed .menu > ul > .menu-item.sub-menu > .sub-menu-list {
    margin-left: -3px !important;
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    margin: 0;
    height: 100vh;
    font-family: "NotoSansRegular", sans-serif;
    color: #3f4750;
    font-size: 1rem;
}

a {
    text-decoration: none;
}

@media (max-width: 992px) {
    #btn-collapse {
        display: none;
    }
}

.layout .sidebar .zastupnik-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

    .layout .sidebar .zastupnik-logo > div {
        /*width: 35px;
        min-width: 35px;
        height: 35px;
        min-height: 35px;*/
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 8px;
        color: white;
        font-size: 24px;
        font-weight: 700;
        /*background-color: #34485E;*/
        /*background-color: #000000;*/
        margin-right: 10px;
    }

        .layout .sidebar .zastupnik-logo > div > img {
            height: 40px;
        }

    .layout .sidebar .zastupnik-logo > h5 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 20px;
        line-height: 30px;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s;
        opacity: 1;
        margin-bottom: 0.1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        text-shadow: 1px 1px 2px black;
    }

.layout .sidebar .footer-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    font-size: 0.8em;
    padding: 20px 0;
    border-radius: 8px;
    width: 180px;
    min-width: 190px;
    margin: 0 auto;
    background-color: #162d4a;
}

    .layout .sidebar .footer-box img.react-logo {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
    }

    .layout .sidebar .footer-box a {
        color: #fff;
        font-weight: 600;
        margin-bottom: 10px;
    }

.layout .sidebar .sidebar-collapser {
    -webkit-transition: left, right, 0.3s;
    transition: left, right, 0.3s;
    position: fixed;
    left: calc(250px - 44px);
    top: 77px;
    width: 20px;
    height: 20px;
    /*border-radius: 50%;*/
    /*background-color: #00829f;*/
    background-color: #EAEAEA;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 1.2em;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    z-index: 111;
    cursor: pointer;
    color: #171717;
    /*-webkit-box-shadow: 1px 1px 4px #0c1e35;
    box-shadow: 1px 1px 4px #0c1e35;*/
}

.layout .sidebar.has-bg-image .footer-box {
    background-color: rgba(22, 45, 74, 0.7);
}

.layout .sidebar.collapsed .zastupnik-logo > h5 {
    opacity: 0;
}

.layout .sidebar.collapsed .footer-box {
    display: none;
}

.layout .sidebar.collapsed .sidebar-collapser {
    left: 18px;
}

    .layout .sidebar.collapsed .sidebar-collapser img {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

.layout .sidebar.collapsed .sidebar-collapser-2 img {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.sidebar-collapser-2.menu-icon {
    margin-left: 190px;
}

.layout .sidebar .menu .menu-item a:hover .sidebar-collapser-2.menu-icon img {
    animation: initial;
}

.layout .sidebar #btn-collapse.sidebar-collapser-2 {
    /*-webkit-transition: left, right, 0.3s;*/
    /*transition: left, right, 0.3s;*/
    /*transition: all 1s ease-in-out;*/
    cursor: pointer;
    height: 25px;
}

.layout .sidebar.collapsed .sidebar-collapser-2.menu-icon {
    margin-left: initial;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #6c757d;
}

    .badge.primary {
        background-color: #ab2dff;
    }

    .badge.secondary {
        background-color: #079b0b;
    }

.sidebar-toggler {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 10;
}

.social-links a {
    margin: 0 10px;
    color: #3f4750;
}



.layout {
  z-index: 1;
  .header {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  .content {
    padding: 12px 50px;
    display: flex;
    flex-direction: column;
  }

  .footer {
    text-align: center;
    margin-top: auto;
    margin-bottom: 20px;
    padding: 20px;
  }
}

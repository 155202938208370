html {
    scroll-behavior: smooth;
}

.site-under-construction {
    background-color: yellow;
    color: red;
    font-size: 24px;
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.main-wrapper {
    /*background: rgb(182,182,182);
    background: radial-gradient(circle, rgba(182,182,182,0.9780287114845938) 84%, rgba(142,142,142,1) 96%);*/
    /*background: rgb(203,203,203);
    background: radial-gradient(circle, rgba(203,203,203,0.9780287114845938) 77%, rgba(142,142,142,1) 94%);*/
    /*background: rgb(232,232,232);
    background: radial-gradient(circle, rgba(232,232,232,0.9780287114845938) 77%, rgba(177,176,176,1) 94%);*/
    color: #000;
}

.feature-icon {
    width: 6rem;
    height: 6rem;
    border-radius: .75rem;
}

.feature-icon-image {
    width: 100%;
    height: 100%;
}

.bold {
    font-weight: bold;
}

.nav-menu-list {
    display: initial;
}

    .nav-menu-list .nav-item {
        text-align: center;
    }

        .nav-menu-list .nav-item .nav-link {
            color: initial;
            font-weight: 400;
        }

            .nav-menu-list .nav-item .nav-link.active {
                color: #fff;
                background-color: #1b6ec2;
                border-color: #1861ac;
            }

.home-logo {
    margin: 4px 6px 4px 10px;
}

/*.navbar-sidebar {
    background: rgb(232,232,232);
    background: radial-gradient(circle, rgba(232,232,232,0.9780287114845938) 77%, rgba(177,176,176,1) 94%);
}*/


.pricing tbody tr:first-of-type th, .pricing tbody tr:first-of-type td {
    padding-top: 20px;
}

.pricing tbody tr:last-of-type th, .pricing tbody tr:last-of-type td {
    padding-bottom: 20px;
}

#section-pricing .p-tabview .p-tabview-panels {
    background-color: inherit;
}

#section-pricing .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
#section-pricing .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: inherit;
    padding: 30px 30px;
    font-size: calc(1.275rem + 0.3vw);
}

#section-pricing .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    font-weight: bold;
}

@media (min-width: 1200px) {
    #section-pricing .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
    #section-pricing .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        font-size: 1.5rem;
    }
}

#section-pricing .list-unstyled {
    text-align: left;
}

    #section-pricing .list-unstyled li {
        margin-bottom: 10px;
    }

#section-pricing .p-tabview-title {
    white-space: inherit;
}

#section-pricing .p-tabview-nav {
    justify-content: center;
}

.kalkulator {
    text-align: left;
}

    .kalkulator .number-selector {
        margin-left: 30px;
    }

        .kalkulator .number-selector input {
            width: 60px;
        }

.land-page-menu .nav-item .nav-link {
    color: #000;
}

    .land-page-menu .nav-item .nav-link.active {
        color: #fff;
        background-color: #1b6ec2;
        border-color: #1861ac;
    }

    .land-page-menu .nav-item .nav-link:hover {
        color: #fff;
        background-color: #0B5ED7;
        border-color: #0A58CA;
    }

@font-face {
    font-family: freestyle script;
    font-style: normal;
    font-weight: 400;
    src: local('Freestyle Script'),url(FREESCPT.woff) format('woff')
}

.rokovnik-u-telefonu {
    /*font-family: 'Freestyle Script', sans-serif;*/
    font-weight: 500 !important;
    font-size: 3.5rem !important;
    text-align: center;
}

.omogucava-advokatima {
    text-align: center;
}

.checked-circle-list {
    list-style: none;
}

    .checked-circle-list li:before {
        content: '\2713';
        display: inline-block;
        position: relative;
        width: 18px;
        height: 18px;
        margin-right: 8px;
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
        color: #000;
        border: 2px solid #000;
        border-radius: 50%;
        text-align: center;
    }

@media (min-width: 1200px) {
    .min-height-card-1 {
        min-height: 170px;
    }

    .min-height-card-3 {
        min-height: 440px;
    }

    .min-height-card-2 {
        min-height: 160px;
    }
}

.feature-img {
    border: 2px solid #CA9442;
    border-radius: 1rem;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: #6C757D;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: #CA9442;
    box-shadow: none !important;
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon, .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    color: #CA9442;
}

@media (max-width: 992px) {
    .feature-list-item {
        margin-bottom: 1rem !important;
    }

    .feature-text-container {
        margin-bottom: 1.5rem !important;
        margin-top: 1rem !important;
    }
}

@media (min-width: 992px) {
    .feature-list-item {
        margin-bottom: 3rem !important;
    }
}

.home-screen-button {
    width: 170px;
}

.cenovnik-button {
    width: 240px;
}

.mt-4s {
    margin-top: 2rem !important;
}

.niste-sigurni-picture {
    border-radius: 1rem;
}

.home-accordion .p-accordion .p-accordion-header-link {
    background-color: transparent;
    border-color: transparent;
    font-family: "NotoSansRegular", sans-serif;
    box-shadow: none !important;
}

.home-accordion .p-accordion .p-accordion-content {
    /*background-color: #efefef;*/
    /*border-color: transparent;*/
    font-family: "NotoSansRegular", sans-serif;
    /*padding-top: 0;*/
}

.home-accordion .p-accordion-tab {
    margin-bottom: 1rem !important;
}

.home-carousel .carousel-content, .carousel-main, .story-carousel {
    font-family: "NotoSansRegular", sans-serif;
}

.home-carousel {
    border-radius: 1rem;
}

.p-carousel-item {
    height: 0;
}

.p-carousel-item-active {
    height: auto;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: #CA9442;
}

.p-carousel .p-carousel-content .p-carousel-prev .pi, .p-carousel .p-carousel-content .p-carousel-next .pi {
    color: #CA9442;
    font-size: 3rem;
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon, .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    color: #CA9442;
    font-size: 3rem;
}

.p-galleria .p-galleria-item-nav {
    z-index: 10;
    box-shadow: none !important;
}

.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
    width: 3rem;
    height: 3rem;
    /*position: absolute;*/
    box-shadow: none !important;
}

/*p-carousel-prev-icon pi pi-chevron-left {
    font-size: 5rem !important;
}*/

.custom-carousel-item-container {
    padding: 1rem 1rem 0.5rem 1rem;
    margin: 0.5rem;
}

@media (max-width: 767px) {
    .custom-carousel-item-container {
        padding: 1rem 0 0.5rem 0;
        margin: 0.5rem;
    }
}

.p-scrolltop.p-link {
    background-color: #1b6ec2;
    border-color: #1861ac;
}

    .p-scrolltop.p-link:hover {
        background-color: #0B5ED7;
        border-color: #0A58CA;
    }

.accordion-zasto-izabrati .p-accordion .p-accordion-header-link {
    font-size: 2rem;
    font-weight: 500;
}

    .accordion-zasto-izabrati .p-accordion .p-accordion-header-link .p-accordion-toggle-icon {
        visibility: hidden;
    }

.accordion-zasto-izabrati .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background-color: transparent;
    border-color: transparent;
}

.accordion-zasto-izabrati .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none !important;
}

.accordion-zasto-izabrati .p-accordion .p-accordion-header .p-accordion-header-link:hover {
    background-color: transparent !important;
    border-color: transparent !important;
}

.accordion-zasto-izabrati .p-accordion .p-accordion-content {
    border: none;
}

.accordion-zasto-izabrati {
    width: 100%;
}

    .accordion-zasto-izabrati .p-accordion-header-text {
        margin: 0 auto;
    }